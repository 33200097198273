.dashboard_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.dashboard-upper-actionbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .dashboard-upper-actionbar-header {
    font-family: $font-family-Poppins-SemiBold;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
  }
}
.dashboard-cards-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  .dashboard-card {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    background-color: $color_white;
    border-radius: 4px;
    min-width: 15rem;

    .dashboard-card-header {
      font-family: $font-family-Poppins-Bold;
      font-size: 20px;
      font-weight: 700;
      line-height: 32px;
      text-align: left;
    }
    .dashboard-card-sub-header {
      font-family: $font-family-Poppins-Regular;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
    }
  }
}
.dashboard-referals-table-container {
  background-color: $color_white;
  height: calc(100% - 3.5rem);
  display: flex;
  flex-direction: column;
  .dashboard-referals-table {
    height: 100%;
    overflow: auto;
  }
}
.add-credit-price-button {
  padding: 10px 16px 10px 12px;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #328df6;
  cursor: pointer;
  color: #328df6;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-credit-btn {
  font-family: $font-family-Poppins-SemiBold;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  color: #2467c6;
  cursor: pointer;
}
.manage-subscription-annul-plan-card {
  height: 2.25rem;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #eaf2fe;
  color: #1b4d94;
  border-radius: 4px;
}
.manage-subscription-price-container {
  display: flex;
  flex-direction: column;
  padding: 12px;
  box-shadow: 0px 2px 4px -2px #1018280f;
  box-shadow: 0px 4px 8px -2px #1018281a;
  border-radius: 4px;
  font-size: 14px;
  gap: 12px;
  margin-bottom: 1rem;
}
.add-credit-current-balance-container {
  padding: 16px 12px;
  box-shadow: 0px 1px 2px 0px #1018280f;
  box-shadow: 0px 1px 3px 0px #1018281a;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 2rem;
  .add-credit-current-balance-container-header {
    font-family: $font-family-Poppins-Bold;
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
  }
  .add-credit-current-balance-container-sub-header {
    font-family: $font-family-Poppins-Regular;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #636a75;
  }
}
.amount-to-be-aaded-text {
  font-family: $font-family-Poppins-SemiBold;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  margin-bottom: 24px;
}
.add-amount-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-radius: 4px;
  border: 1px solid #e8e9ee;
}
.add-credit-price-container {
  display: flex;
  flex-direction: column;
  padding: 12px;
  box-shadow: 0px 2px 4px -2px #1018280f;
  box-shadow: 0px 4px 8px -2px #1018281a;
  border-radius: 4px;
  font-size: 14px;
  gap: 12px;
  margin-bottom: 1rem;
}