.settings-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.settings-upper-actionbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .settings-upper-actionbar-header {
    font-family: $font-family-Poppins-SemiBold;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
  }
}
.settings-cards-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
  background-color: $color_white;
  width: 100%;
  height: 100%;
  .setting-card {
    border-radius: 4px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: $color_white;
    .settings-card-header {
      font-family: $font-family-Poppins-SemiBold;
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      text-align: left;
    }
    .discounts-card {
      min-width: 22rem;
      box-shadow: 0px 1px 2px 0px #1018280f;
      box-shadow: 0px 1px 3px 0px #1018281a;
      background-color: $color_white;
      border-radius: 4px;
      padding: 12px;
      display: flex;
      align-items: center;
      gap: 12px;
      .discount-card-header {
        font-family: $font-family-Poppins-Regular;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        color: #535a5f;
      }
      .discount-card-value {
        font-family: $font-family-Poppins-SemiBold;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
      }
    }
  }
}
