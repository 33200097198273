@font-face {
  font-family: "DMSans-Medium";
  src: url("../resources/fonts/DMSans-Medium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DMSans-Bold";
  src: url("../resources/fonts/DMSans-Bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DMSans-Regular";
  src: url("../resources/fonts/DMSans-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../resources/fonts/Montserrat-SemiBold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Regular";
  src: url("../resources/fonts/Poppins-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Semibold";
  src: url("../resources/fonts/Poppins-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("../resources/fonts/Poppins-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
