.table {
  border-spacing: 0;
  // border: 2px solid #dbdbdb;
  min-width: 100%;
  table-layout: fixed;
  overflow-x: auto;

  th {
    font-family: $font-family-Poppins-SemiBold;
    font-size: 0.75rem;
    letter-spacing: 0;
    font-weight: normal;
    color: $text_600 !important;
    overflow: hidden;
    position: sticky;
    text-overflow: ellipsis;
    text-align: start;

    span {
      vertical-align: middle;
    }
  }

  tr {
    // display: flex;
    cursor: pointer;

    &:hover {
      background-color: $table_hover-color !important;
    }

    &:last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  th,
  td {
    flex: 1;
    margin: 0;
    padding: 0.5rem;
    padding-left: 24px;
    vertical-align: middle;
    // font-size: 0.8rem;
    // border-bottom: 1px solid #dbdbdb;
    // border-top: 1px solid #dbdbdb;

    &:last-child {
      border-right: 0;
    }
    &:first-child {
      border-right: 1px solid #e8e9ee !important;
    }
  }

  thead {
    // background-color: white;
    position: sticky;
    top: 0vh;
    background-color: $color_white;
    z-index: 1000;
    tr {
      border-bottom: 1px solid #e8e9ee !important;
    }
    th {
      border-width: 0;
      &:first-child {
        border-right: 1px solid #e8e9ee !important;
      }
    }
  }

  tbody {
    tr {
      background-color: $color_white !important;
      > *:first-child {
        font-family: $font-family-Poppins-Regular;
        letter-spacing: 0.2px;
        font-size: 0.875rem;
        color: $text_600;
      }

      > * {
        font-family: $font-family-Poppins-Regular;
        letter-spacing: 0;
        font-size: 0.875rem;
        border-bottom: 1px solid #e8e9ee !important;
        color: $text_600;
      }
    }
  }
}

#answerOptionTable {
  .table {
    border-spacing: 0;
    // border: 2px solid #dbdbdb;
    max-width: 80%;
    width: 50%;
    table-layout: fixed;
    overflow-x: auto;

    th {
      font-family: $font-family-Poppins-SemiBold;
      font-size: 0.75rem;
      letter-spacing: 0;
      font-weight: normal;
      color: $light-gray-text !important;
      overflow: hidden;
      position: sticky;
      text-overflow: ellipsis;

      span {
        vertical-align: middle;
      }
    }

    tr {
      // display: flex;
      cursor: pointer;

      &:hover {
        background-color: $brand-secondary-color !important;
      }

      &:last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      flex: 1;
      margin: 0;
      padding: 0.5rem;
      padding-left: 24px;
      border-color: transparent;
      // font-size: 0.8rem;
      // border-bottom: 1px solid #dbdbdb;
      // border-top: 1px solid #dbdbdb;

      &:last-child {
        border-right: 0;
      }
    }

    thead {
      position: sticky;
      top: 0vh;
      background-color: white;
    }

    tbody {
      // display: flex;
      // flex-direction: column;
      // width: 100%;

      tr {
        > *:first-child {
          font-family: $font-family-Poppins-SemiBold;
          letter-spacing: 0.2px;
          font-size: 0.875rem;
          width: 25%;
        }

        > * {
          font-family: $font-family-Poppins-Regular;
          letter-spacing: 0;
          font-size: 0.875rem;
          width: 85%;
        }
      }
    }
  }
}
