.navbar {
  height: $navbar-height;
  background-color: $color_white;
  box-shadow: 0 1px 0 0 rgba(139, 153, 177, 0.28);
}
.menuBar {
  //   padding: 0 20px;
  //   border-bottom: solid 1px #e8e8e8;
  width: 100%;
  overflow: auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //   box-shadow: 0 0 30px #f3f1f1;
}

.logo {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 185px;
  padding: 0 1rem;
}

.logo img {
  height: 30px;
}

.menuCon {
  display: flex;
}
.exam-watch-wrapper {
  display: flex;
}
.rightMenu {
  height: 100%;
  display: grid;
  place-items: center;
}
.menuCon .ant-menu-item {
  padding: 0px 5px;
}

.menuCon .ant-menu-submenu-title {
  padding: 10px 20px;
}

.menuCon .ant-menu-item a,
.menuCon .ant-menu-submenu-title a {
  padding: 10px 15px;
}

.menuCon .ant-menu-horizontal {
  border-bottom: none;
}

.menuCon .rightMenu {
  float: right;
}

.barsMenu {
  float: left;
  display: none;
}
.navbar-breadcrum-container {
  display: flex;
  padding: 0 1rem;
}
.barsMenu > span {
  display: block;
}

.navbar-logo-label {
  font-family: "Montserrat-SemiBold";
  font-size: 24px;
  color: $primary;
  letter-spacing: 4.08px;
  text-align: left;
  line-height: 31px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.navbar-profile-info-container {
  height: 100%;
  width: 100%;
}
.navbar-profile-info-header-container {
  display: flex;
  width: 100%;
}
.navbar-profile-info-name {
  font-family: $font-family-Poppins-SemiBold;
  text-align: left;
  width: 100%;
}
.navbar-profile-info-email {
  font-family: $font-family-Poppins-Regular;
  font-size: 14px;
  text-align: left;
  width: 100%;
}
.navbar-profile-info-seperator {
  display: inline-block;
  width: 100%;
  border: 1px solid #efefef;
}
.navbar-profile-info-signinAs-container {
  display: flex;
  width: 100%;
  margin-top: 10px;
  margin-left: 10px;
}
.signInAs-header-label {
  font-family: $font-family-Poppins-Regular;
  font-size: 12px;
  font-weight: 400;
  color: #979696;
}
.signInAs-header-value {
  font-family: $font-family-Poppins-Regular;
  font-size: 14px;
  font-weight: 400;
}
.logout-container {
  width: 100%;
  height: 2.5rem;
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.logout-label {
  font-family: $font-family-Poppins-SemiBold;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0px;
  margin-left: 1rem;
  cursor: pointer;
}
.navbar-right-container {
  display: flex;
  align-items: center;
  a {
    font-family: $font-family-Poppins-SemiBold;
  }
}
.profile-info-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}
@media (max-width: 771px) {
  .exam-watch-container {
    margin: 0;
  }
  .exam-watch-container {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .barsMenu {
    display: flex;
  }
  .navbar-breadcrum-container {
    display: none;
  }
}
.navbar-breadcrumb-container {
  display: flex;
  align-items: center;
  font-family: $font-family-Poppins-Regular;
  font-size: "14px";
  gap: 4px;
}
