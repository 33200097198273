@import "./themes";
@import "./variables";
@import "./font";
@import "./tables";
@import "./loader";
@import "./login";
@import "./toast";
@import "./sidebar";
@import "./navbarNew";
@import "./dashboard";
@import "./transactions";
@import "./settings";

html {
  overflow-y: hidden;
  font-family: $font-family-Poppins-Regular;
}
#root {
  width: 100vw;
  height: 100vh;
}
label {
  font-family: $font-family-Poppins-SemiBold;
}
.sidebar-main-container {
  display: flex;
  flex-direction: row;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.flex {
  display: flex;
}
.gap-1rem {
  gap: 1rem;
}
.gap-12px {
  gap: 12px;
}
.p-1rem {
  padding: 1rem;
}
.space-between {
  justify-content: space-between;
}
.flex-1 {
  flex: 1;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-align-center {
  display: flex;
  align-items: center;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-space-between {
  justify-content: space-between;
}
.button-v2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: none;
  height: 2.5rem;
  padding: 10px 16px 10px 16px;
  border-radius: 4px;
  background-color: $primary;
  color: $color_white;
  border: none;
  font-family: "Poppins-Regular";
  font-size: 14px;
  height: 40px;
  cursor: pointer;
  &:hover {
    background-color: $color_primary_300;
  }
}
.outline-btn {
  border: 1px solid $primary;
  background: $color_white;
  color: #2467c6;
  &:hover {
    color: $color_primary_300;
    border: 1px solid $color_primary_300;
    background: $color_white;
  }
}
.disable-btn {
  background: #dbe4f0;
  color: #328df6;
  cursor: default;
  &:hover {
    background: #dbe4f0;
    color: #328df6;
  }
}
.error-message {
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: large;
}
.main-container-v2 {
  // margin-top: $navbar-height;
  max-height: calc(100vh - #{$navbar-height});
  // overflow: auto;

  main {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: $color_nutral100;
    // box-shadow: 0 0 4px 0 rgba(151, 137, 137, 0.07);
    .sidebar-main-children-container {
      max-width: 120rem;
      height: calc(100% - 2rem);
      // margin: auto;
      padding: 1rem;
      display: flex;
      flex-direction: column;
    }
  }
}
@media (max-width: 771px) {
  .sidebar-main-container {
    height: calc(100vh - #{$navbar-height});
  }
  .main-container-v2 {
    width: 100%;
    main {
      max-width: 100%;
    }
  }
  .container {
    min-width: 100% !important;
  }
}
.tag-container {
  height: 1.75rem;
  width: fit-content;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: $font-family-Poppins-Regular;
  line-height: 20px;
  border-radius: 4px;
}
.modal-outer-container {
  min-width: 27.5rem;
  .modal-header-container {
    min-height: 3rem;
    width: 100%;
    display: flex;
    margin-bottom: 28px;
    align-items: center;
    justify-content: space-between;
    img {
      height: 2.25rem;
      width: 2.25rem;
    }
    .header {
      font-family: $font-family-Poppins-SemiBold;
      font-size: 16px;
    }
    .header-desc {
      font-family: $font-family-Poppins-Regular;
      font-size: 14px;
      color: #71777b;
    }
  }
  .modal-desc {
    font-family: $font-family-Poppins-Regular;
    font-size: 14px;
  }
  .modal-bottom-actionbar {
    height: 4rem;
    width: 100%;
    padding: 12px, 20px, 12px, 20px;
    border-top: 1px solid #e9eaeb;
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 28px;
    gap: 1rem;
  }
}
.partner-tag-container {
  width: fit-content;
  padding: 2px 8px 2px 8px;
  border-radius: 4px;
  background-color: #dceaef;
  font-family: $font-family-Poppins-Regular;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #2d768b;
}
.landing-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  gap: 1rem;
  .header {
    font-family: $font-family-Poppins-SemiBold;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
  }
  .sub-header {
    font-family: $font-family-Poppins-Regular;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  }
}
.modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(150, 150, 150, 0.75);
  z-index: 10000000000;
}
