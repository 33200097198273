.transaction-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.transaction-upper-actionbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .transaction-upper-actionbar-header {
    font-family: $font-family-Poppins-SemiBold;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
  }
}
