$tablet: 577px;
// $primary: #009bae;
$primary: $color_primary;
$primary-light: $color_primary_300;
$brand-secondary-color: $color_primary_100;
$gray: $text_color_400;
$light-gray: $text_color_300;
$sidebar-bg-color: $color_white;
$payment-bg-color: $color_primary_100;
$payment-button-bg-color: $color_primary_300;
$payment--popular-button-bg-color: $color_primary;
$font-family-DmSans-regular: "DmSans-Regular";
$font-family-DmSans-bold: "DmSans-Bold";
$font-family-DmSans-medium: "DmSans-Medium";
$font-family-Poppins-Regular: "Poppins-Regular";
$font-family-Poppins-SemiBold: "Poppins-SemiBold";
$font-family-Poppins-Bold: "Poppins-Bold";
$sidebar-width: 15rem;
$bottom-actionbar-height: 3.7rem;

$navbar-height: 3.7rem;
$navbar-background-color: white;
$navbar-item-hover-background-color: #c8dce2;
$navbar-dropdown-item-hover-background-color: #c8dce2;
$navbar-dropdown-item-active-background-color: #c8dce2;
$navbar-item-hover-color: #444;
$navbar-dropdown-item-hover-color: #444;
$navbar-dropdown-item-active-color: #444;

$main-div-margin: 0.825rem;
$main-div-height: calc(100vh - #{$navbar-height} - (2 *#{$main-div-margin}));
$main-div-width: calc(
  100vw - #{$sidebar-width} - 1.5px - (2 *#{$main-div-margin})
);

$tab-content-height: calc(#{$main-div-height} - 61px - 55px - 20px);
$layout-upper-actionbar-container-height: 3.5rem;
$tabs-height: 2.813rem;
$dark-gray-text: #202726;
$light-gray-text: #6d7070;
