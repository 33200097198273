#toast-container {
  position: fixed;
  min-width: 200px;
  max-width: 400px;
  max-height: 250px;
  top: 73px;
  right: 25px;
  z-index: 1000;
  .message.hide {
    transition: transform 300ms ease-out;
    transform: translateX(100vw);
  }
  .message.show {
    transition: transform 300ms ease-out;
    transform: translateX(0);
  }
}
#api-loader-container {
  position: fixed;
  bottom: calc(50% - 100px);
  right: calc(50% - 100px);
  z-index: 1000;
  min-width: 200px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#indefinite-loader-container {
  position: fixed;
  inset: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.toast-message-container {
  max-width: 25rem;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 12px;
  font-family: $font-family-Poppins-SemiBold;
  border-radius: 4px;
}
.success {
  background-color: #d9e6dc;
  color: #198a48;
  border: 1px solid #439d62;
}
.error {
  background-color: #fadcda;
  color: #c73733;
  border: 1px solid #f94948;
}
.info {
  background-color: #eaf2fe;
  color: $primary;
  border: 1px solid $primary;
}
.warning {
  background-color: #fbe3b1;
  color: #a06b09;
  border: 1px solid #d39822;
}
