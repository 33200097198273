.sidebar {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  // width: $sidebar-width;
  height: calc(100vh - #{$navbar-height});
  // box-shadow: 0 0 3px 2px rgba(172, 183, 199, 0.06);
  background-color: #ffffff;
  // margin-top: $navbar-height;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease 0s;
  label {
    height: 48px;
  }
}
.uploadscan-right-sidebar {
  display: flex;
  align-items: baseline;
  position: absolute;
  right: 0;
  // min-width: 20rem;
  height: calc(100% - 4rem);
  box-shadow: 0 0 3px 2px rgba(172, 183, 199, 0.06);
  background-color: #ffffff;
  // margin-top: $navbar-height;
  margin-right: 1.5px;
  flex-direction: column;
  transition: all 0.3s ease 0s;
  gap: 1rem;
  padding: 1rem;
}
.sidebar-item-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
  label {
    height: 2.875rem;
    padding: 0 1rem;
    &:hover {
      background-color: $sidebar-bg-color;
    }
  }
  input {
    min-width: 100%;
    height: 2.875rem;
    border-color: transparent;
    &:not(:checked) + .sidebar-tab {
      background-color: white;
      border-radius: 0px;
      &:hover {
        background-color: $brand-secondary-color !important;
      }
    }
    &:checked + .sidebar-tab {
      background-color: $sidebar-bg-color !important;
      border-radius: 0px;
    }
  }
}
.sidebar-item-checked {
  background-color: $sidebar-bg-color;
}
.sidebar-payment-button-container {
  width: 100%;
  min-height: 10.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: 1px solid #e8e9ee;
  border-radius: 8px;
  .sidebar-payment-button-header {
    font-family: $font-family-Poppins-SemiBold;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }
  .sidebar-payment-button-content-text {
    font-family: $font-family-Poppins-Regular;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #71777b;
    margin-bottom: 12px;
  }
}
.sidebar-collape-image-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar-name-label {
  font-family: $font-family-Poppins-Regular;
  font-size: 16px;
  line-height: 20px;
  color: #363e45;
  margin-left: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 14px 12px;
}
.sidebar-link-label-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 2.875rem;
}
.sidebar-configuration-label {
  display: flex;
  align-items: center;
  span {
    font-size: 14px;
    font-family: $font-family-Poppins-SemiBold;
    color: $light-gray-text;
    display: flex;
    align-items: center;
    margin-left: 1.5em;
  }
}
@media (max-width: 771px) {
  .sidebar {
    display: none;
    height: 100vh;
  }
}
