.loginPage {
  // TODO: Check why hidden is needed
  // Hint: has-addons class is creating some issue
  overflow-x: hidden;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f6f7;
  font-weight: 500;
  flex-direction: column;
}
.brand-logo {
  flex: 2;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  header {
    a {
      display: flex;
      align-items: center;
      .content {
        margin-left: 0.3rem;
        height: 1.9rem;
        h2 {
          font-family: "Montserrat-SemiBold";
          font-size: 2.125rem;
          letter-spacing: 7.71px;
          text-align: left;
          line-height: 1.938rem;
          color: $primary;
        }
      }
      img {
        height: 2rem;
        width: 2.125rem;
        margin-right: 0.3rem;
      }
    }
  }
}
.loginCard-container {
  display: flex;
  flex: 3;
  align-items: baseline;
  justify-content: center;
  padding-top: 2.5rem;
}
.loginForm {
  padding-left: 3.125rem;
  padding-right: 3.125rem;
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
  max-height: 27rem;
  min-height: 27rem;
  max-width: 27rem;
  min-width: 27rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.loginWithGoogle {
  border: 1px solid #bfcccb !important;
  border-radius: 3px;
  width: 100%;
  font-family: $font-family-Poppins-SemiBold;
  font-size: 16px;
  letter-spacing: 0;
  justify-content: center !important;
  img {
    height: 1.1rem;
    margin: 4px 10px 4px 4px;
  }
}

#loginTeacherContainerLabel {
  width: 45%;
  margin-right: 5%;
}
#loginStudentContainerLabel {
  width: 45%;
  margin-left: 5%;
}
.login-type-radio {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0px;
}

.login-type {
  position: relative;
  background: transparent;
  width: 100%;
  input {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 5rem;
    & + .login-type-img-container {
      width: 100%;
      height: 5rem;
      border-radius: 0px;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.17);
    }
    &:not(:checked) + .login-type-img-container {
      background-color: #ffffff;
      transition: background-color 0.3s ease;
      &:hover {
        background-color: $brand-secondary-color;
      }
    }
    &:checked + .login-type-img-container {
      border: 2px solid $primary !important;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.17);
      background-color: $brand-secondary-color !important;
    }
  }
  .login-type-img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    .login-type-img {
      width: 3rem;
      height: 3rem;
    }
  }
  .label-teacher {
    font-family: $font-family-Poppins-Regular;
    font-size: 12px;
    color: $light-gray-text;
    letter-spacing: 0;
    line-height: 16px;
  }
  .label-student {
    font-family: $font-family-Poppins-Regular;
    font-size: 12px;
    color: $light-gray-text;
    letter-spacing: 0;
    line-height: 16px;
  }
}

.email-input {
  background: #ffffff;
  border: 1px solid #bfcccb;
  border-radius: 3px;
  border-radius: 3px;
}

.get-email-button {
  width: 100%;
  color: white;
  justify-content: center !important;
  font-family: $font-family-Poppins-SemiBold;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 0;
}

.login-label-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: fit-content;
  padding-top: 0.625rem;
  padding-bottom: 1.875rem;
  margin-bottom: 0 !important;
  .login-label {
    font-family: $font-family-Poppins-SemiBold;
    font-size: 26px;
    color: #202726 !important;
    letter-spacing: 0;
  }
}

.login-divider {
  font-family: $font-family-Poppins-SemiBold;
}
.login-termsAndCondition-label {
  font-size: 12px;
  display: inline-block;
}
.login-main-container {
  height: 100vh;
  width: 100%;
  display: flex;
  position: relative;
  .left-container {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    .login-left-info-container {
      position: absolute;
      bottom: 0;
      background: #fffffff2;
      display: flex;
      flex-direction: column;
      padding: 1.875rem;
      border-radius: 8px;
      margin: 3.125rem;
    }
    // img {
    //   width: 616px;
    //   height: 616px;
    // }
    .login-header {
      font-family: $font-family-Poppins-Bold;
      font-size: 32px;
      font-weight: 700;
      line-height: 48px;
    }
    .login-sub-header {
      font-family: $font-family-Poppins-Regular;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      span {
        cursor: pointer;
        color: $primary;
      }
    }
  }
  .right-container {
    flex: 1;
    height: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    .inner-container {
      display: flex;
      flex-direction: column;
      width: 28.75rem;
      .logo-img {
        margin-bottom: 40px;
        width: 217.45px;
        height: 59.99px;
      }
      .login-right-container-header {
        font-family: $font-family-Poppins-Bold;
        font-size: 32px;
        line-height: 48px;
      }
      .login-right-container-subheader {
        font-family: $font-family-Poppins-Regular;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 40px;
      }
      .sign-in-as-card-wrapper {
        height: 4.5rem;
        display: flex;
        gap: 24px;
        margin-bottom: 24px;
        .sign-in-as-card {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          box-shadow: 0px 1px 2px 0px #1018280f;
          box-shadow: 0px 1px 3px 0px #1018281a;
          border-radius: 6px;
          padding: 1rem;
          cursor: pointer;
        }
        img {
          height: 100%;
        }
        .card-text {
          font-family: $font-family-Poppins-SemiBold;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          width: 100%;
          margin-right: 12px;
        }
        .card-selected {
          border: 2px solid #abcdfc;
        }
        .sign-in-as-card-radio-btn {
          min-height: 1rem;
          min-width: 1rem;
          border: 2px solid gray;
          border-radius: 50%;
          margin-right: 8px;
        }
        .card-radio-btn-selected {
          border: 3px solid $primary;
        }
      }
      .google-login-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3rem;
        padding: 12px 24px;
        gap: 12px;
        box-shadow: 0px 1px 2px 0px #1018280f;
        box-shadow: 0px 1px 3px 0px #1018281a;
        cursor: pointer;
        margin-bottom: 40px;
        span {
          font-family: $font-family-Poppins-SemiBold;
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
        }
      }
      .login-seperation-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
        label {
          font-family: $font-family-Poppins-Regular;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          flex: 1;
          text-align: center;
          min-width: fit-content;
        }
        .login-seperation-line {
          display: block;
          height: 1px;
          background-color: #e8e9ee;
          flex: 1;
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .login-main-container {
    .left-container {
      display: none;
    }
    .right-container {
      .inner-container {
        width: 100%;
      }
    }
  }
}
